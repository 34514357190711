import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const useAdmin = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function loadGroups() {
      const claims = await getIdTokenClaims();
      if (claims) {
        const roles = claims['https://sky.com/roles'] ?? [];
        setIsAdmin(roles.includes('Admin'));
      }
    }
    loadGroups();
  }, [getIdTokenClaims]);
  return isAdmin;
};

export default useAdmin;
