import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

// Custom theme
const themeLight = {
  palette: {
    type: 'light',
    primary: {
      main: '#0072c9',
    },
    secondary: {
      main: '#73add8',
    },
    success: {
      main: '#299934',
    },
    error: {
      main: '#ff2744',
    },
  },
} as ThemeOptions;

const themeDark = {
  palette: {
    type: 'dark',
    text: {
      primary: '#ffffff',
    },
    background: {
      default: '#000000',
    },
  },
} as ThemeOptions;

const theme = (isDark: boolean) => createMuiTheme(isDark ? themeDark : themeLight);

export default theme;
