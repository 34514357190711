import { Typography, FormControl } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from './sky/Button';
import logo from '../logo.svg';
import logoDark from '../logo_dark.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    formWrapper: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      flexDirection: 'column',
    },
    topBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(5),
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1100px',
      width: '90%',
    },
    actions: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    button: {
      background: '#ffffff',
      color: '#000000',
      borderRadius: 0,
      width: '200px',
      justifyContent: 'center',
    },
    terms: {
      border: `2px solid ${theme.palette.text.primary}`,
      padding: theme.spacing(5),
      height: '70vh',
      maxHeight: '500px',
      overflow: 'auto',
    },
  })
);

const ConsentConfirmationDialog = () => {
  const query = useQuery();

  const auth0Domain = query.get('auth0_domain');
  const auth0State = query.get('state');
  const isCherry = !!query.get('isCherry');

  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const prefersDarkMode = isCherry || isDarkMode;

  const classes = useStyles();

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const schema = yup.object().shape({
    confirm: yup.boolean().default(true).notRequired(),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getRegisterProps = (name: string, type: string) => {
    const { ...registerProps } = register(name);
    switch (type) {
      case 'radio':
      case 'checkbox':
        return {
          ...registerProps,
        };
      default:
        return {
          ...registerProps,
          error: !!errors[name],
          helperText: errors[name]?.message,
          variant: 'standard',
          fullWidth: true,
          margin: 'normal',
        };
    }
  };

  if (!auth0Domain || !auth0State) {
    return <p>Error</p>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <img width={100} src={prefersDarkMode ? logoDark : logo} alt="Sky" />
        <Typography variant="h4" component="p" id="confirmation-dialog-title">
          {moment(time).format('hh:mm a')}
        </Typography>
      </div>
      <div className={classes.formWrapper}>
        <form
          id="add-task-form"
          noValidate
          method="post"
          className={classes.form}
          action={`https://${auth0Domain}/continue?state=${auth0State}`}
        >
          <Typography variant="h4" id="confirmation-dialog-title" gutterBottom>
            Privacy notice
          </Typography>
          <div className={classes.terms}>
            <Typography component="h3" variant="h6">
              What happens during the recordings?
            </Typography>
            <Typography gutterBottom>
              <ul>
                <li>
                  We will ask you/your friends/your family to record certain activities. These
                  activities will be given as tasks within the Video Collection app.
                </li>
                <li>
                  We will record your audio and video for the duration of the session, for a maximum
                  of one minute, or when you say stop, whichever comes first.
                </li>
              </ul>
            </Typography>
            <Typography component="h3" variant="h6">
              How will the video recordings be used?
            </Typography>
            <Typography gutterBottom>
              We will use the recordings to -
              <ul>
                <li>
                  Develop our products and services so they recognise the poses made by people in
                  front of them; and
                </li>
                <li>
                  Test how effective the products and services that are being developed by Sky
                  employees or third-parties are at detecting activities in front of the camera.
                </li>
              </ul>
              We will share video recordings with suppliers so that they can improve the technology
              they are building for us.
              <br />
              <br />
              We will delete all video recordings at the end of the trials and ensure that any of
              the above mentioned suppliers also delete the recordings too.
            </Typography>
            <Typography component="h3" variant="h6">
              What information will be collected about me/my friends/my family?
            </Typography>
            <Typography gutterBottom>
              <ul>
                <li>
                  In addition to the activities that you record for us, we will also ask you and any
                  individuals taking part in the prescribed activities to provide:
                  <ul>
                    <li>Environment where the recording took place</li>
                  </ul>
                </li>
              </ul>
              If at any time we need to obtain any further personal data from you other than what is
              listed in this notice, we will contact you and provide you with detail of that as well
              as request your consent for this data to be processed.
              <br />
              <br />
              For further detail on how we process data and how to exercise your data subject
              rights, please visit{' '}
              <a target="_blank" rel="noreferrer" href="https://www.sky.com/privacy">
                www.sky.com/privacy
              </a>
              <br />
              <br />I have read the information above and consent to the collection and transfer of
              personal data as described in this notice.
            </Typography>
          </div>
          <FormControl>
            <input
              defaultChecked
              type="checkbox"
              id="accepting-confirm"
              hidden
              value="yes"
              {...getRegisterProps('confirm', 'checkbox')}
            />
          </FormControl>
          <div className={classes.actions}>
            <Button
              color="primary"
              type="submit"
              form="add-task-form"
              className={prefersDarkMode ? classes.button : ''}
            >
              Got it
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConsentConfirmationDialog;
