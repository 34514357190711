import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },
    wrapper: {
      padding: theme.spacing(2, 0),
    },
  })
);

type LayoutProps = {
  userName?: string;
};

const Layout: FC<LayoutProps> = ({ children, userName }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header userName={userName} />
      <Container maxWidth={false}>
        <div className={classes.wrapper}>{children}</div>
      </Container>
    </div>
  );
};

export default Layout;
