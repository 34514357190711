import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { getErrorMessage } from '../utils/messages';
import logo from '../logo.svg';
import useAdmin from '../hooks/useAdmin';
import Button from './sky/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    home: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: 30,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[900],
    },
    userName: {
      position: 'relative',
      top: '-1px',
      padding: theme.spacing(0, 2),
    },
  })
);

type HeaderProps = {
  userName?: string;
};

const Header: FC<HeaderProps> = ({ userName }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = useAdmin();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      await logout({ returnTo: window.location.origin });
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };
  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Toolbar>
          <Link className={classes.home} to="/">
            <img className={classes.logo} src={logo} alt="Sky" />
          </Link>
        </Toolbar>
        <Typography variant="h6" className={classes.title}>
          Video Collection
        </Typography>
        {isAdmin && (
          <Link to="/admin/dashboard" className={classes.link}>
            <Button className="btn-no-border" color="secondary">
              Admin Dashboard
            </Button>
          </Link>
        )}
        {userName && <Typography className={classes.userName}>Logged in as {userName}</Typography>}
        <Button className="btn-no-border" onClick={handleLogout} color="secondary">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
