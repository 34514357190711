import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '../components/sky/Button';
import logo from '../logo.svg';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: theme.spacing(3),
    },
    logo: {
      height: 30,
    },
    title: {
      margin: theme.spacing(3),
    },
    description: {
      textAlign: 'left',
      margin: theme.spacing(3, 'auto'),
      maxWidth: '680px',
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(3),
      },
    },
  })
);

const SignInDialog: FC = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  const queryError = query.get('error');
  const queryErrorDesc = query.get('error_description');

  const handleConfirm = () => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
      connection: 'sky-sso-test',
    });
  };

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={logo} alt="Sky" />
      <Typography className={classes.title} component="h1" variant="h3" gutterBottom>
        Video Collection App.
      </Typography>
      <div className={classes.description}>
        <Typography gutterBottom>
          We&apos;re working on an assortment of new features that will allow you to interact with
          apps and content by using and moving your body, allowing you to interact with experiences
          in a whole new way.
        </Typography>
        <Typography gutterBottom>
          We&apos;ve built this app so that you, your friends and family can record yourselves
          performing various actions. We&apos;ll tell you how, you just need to follow and have a
          bit of fun along the way! This is important for us, so that we have the best possible
          chance of designing a solution that works well for a diverse range of customers and
          environments.
        </Typography>
        <Typography gutterBottom>
          Sounds good? Have a read of legal bit before you sign in.
        </Typography>
        {queryError && queryErrorDesc === 'User did not consent!' && (
          <Typography color="error" gutterBottom>
            You need to accept the privacy notice to continue.
          </Typography>
        )}
      </div>
      <Button onClick={handleConfirm} color="primary">
        Sign in with SkySSO
      </Button>
    </div>
  );
};
export default SignInDialog;
