import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { UserSubmission } from '../API';
import TaskUserSubmission from './TaskUserSubmission';
import Loading from './common/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      margin: theme.spacing(4, 1),
    },
    userSubmission: {
      padding: theme.spacing(0, 0, 3),
    },
    userSubmissions: {
      padding: theme.spacing(2, 0, 1),
      display: 'flex',
      flexWrap: 'wrap',
    },
    loadingSubmissions: {
      position: 'relative',
    },
  })
);

export type TaskUserSubmissionsProps = {
  userSubmissions: UserSubmission[];
  loading: boolean;
  deleteSubmission: (pk: string, sk: string) => void;
};

const TaskUserSubmissions = ({
  userSubmissions,
  loading,
  deleteSubmission,
}: TaskUserSubmissionsProps) => {
  const classes = useStyles();
  const isAdminLocation = window.location.href.indexOf('admin') > -1;

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2">
        {isAdminLocation ? 'All Submissions' : 'Your Submissions'}
      </Typography>
      {userSubmissions.length === 0 && !loading && (
        <Typography gutterBottom variant="body2" component="h2">
          You haven&apos;t made any submissions
        </Typography>
      )}
      {loading && (
        <Typography gutterBottom variant="body2">
          Your submission may take a minute to show below
        </Typography>
      )}
      <Grid container className={classes.userSubmissions}>
        {userSubmissions.map((userSubmission: UserSubmission) => (
          <TaskUserSubmission
            deleteSubmission={deleteSubmission}
            className={classes.userSubmission}
            userSubmission={userSubmission}
            key={userSubmission.sk}
          />
        ))}
        {loading && (
          <Grid item xs={12} md={4} className={classes.loadingSubmissions}>
            <Loading position="absolute" />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TaskUserSubmissions;
