import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardContent, CardActions } from '@material-ui/core';
import { UserSubmission } from '../API';
import { toLocalFormattedString } from '../utils/dateTransform';
import VideoPlayer from './VideoPlayer';
import ConfirmDialog from './common/ConfirmDialog';
import Button from './sky/Button';
import Thumbnail from './common/Thumbnail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    userSubmission: {
      position: 'relative',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    actions: {
      justifyContent: 'center',
    },
    camelCase: {
      textTransform: 'capitalize',
    },
    media: {
      position: 'relative',
      height: 220,
    },
  })
);

export type TaskUserSubmissionProps = {
  userSubmission: UserSubmission;
  className: string;
  deleteSubmission: (pk: string, sk: string) => void;
};

const TaskUserSubmission = ({
  userSubmission,
  className,
  deleteSubmission,
}: TaskUserSubmissionProps) => {
  const classes = useStyles();
  const [play, setPlay] = useState<boolean>(false);
  const isAdminLocation = window.location.href.indexOf('admin') > -1;

  const handleDelete = () => {
    deleteSubmission(userSubmission.pk, userSubmission.sk);
  };

  return (
    <Grid item xs={12} md={4} className={classes.root} key={userSubmission.sk}>
      <Card className={`${className} ${classes.userSubmission}`}>
        {play ? (
          <VideoPlayer fileKey={userSubmission.s3TranscodedKey!} fileType="video/mp4" />
        ) : (
          <Thumbnail s3Key={userSubmission.s3ThumbnailKey!} />
        )}
        <CardContent>
          <Typography className={classes.title}>
            Created: {toLocalFormattedString(userSubmission.createdAt)}
          </Typography>
          <Typography className={classes.camelCase}>
            Location: {userSubmission.metadata.location.toLowerCase().replace('_', ' ')}
          </Typography>
          {userSubmission.metadata.appVersion && isAdminLocation && (
            <Typography className={classes.camelCase}>
              App Version: {userSubmission.metadata.appVersion?.toLowerCase().replace('_', ' ')}
            </Typography>
          )}
          {userSubmission.metadata.device && isAdminLocation && (
            <Typography className={classes.camelCase}>
              Device: {userSubmission.metadata.device?.toLowerCase().replace('_', ' ')}
            </Typography>
          )}
          {userSubmission.metadata.source && isAdminLocation && (
            <Typography className={classes.camelCase}>
              Source: {userSubmission.metadata.source?.toLowerCase().replace('_', ' ')}
            </Typography>
          )}
          {isAdminLocation && (
            <Typography>
              File ID:{' '}
              {`${userSubmission.pk.split('|').pop()}:${
                userSubmission.s3Key.split('/').pop()!.split('.')[0]
              }`}
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.actions}>
          <ConfirmDialog
            title="Permanently delete your submission?"
            message='To confirm deletion, click the "Delete" button.'
            onConfirm={handleDelete}
            actionLabel="Delete"
            renderTrigger={(open: () => void) => (
              <Button color="secondary" onClick={open}>
                Delete
              </Button>
            )}
          />
          {play ? (
            <Button color="secondary" onClick={() => setPlay(false)}>
              Hide Video
            </Button>
          ) : (
            <Button color="primary" onClick={() => setPlay(true)}>
              Play
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default TaskUserSubmission;
